// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    font-family: 'Monsterrat', sans-serif;
}

.left-menu__item, .right-menu__item {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    color: #495057;
    margin: 10px 15px;
    cursor: pointer;
    /* border-right: 1px solid #e9ecef; */
}`, "",{"version":3,"sources":["webpack://./src/assets/css/main-menu.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;IAClB,qCAAqC;AACzC;;AAEA;IACI,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,iBAAiB;IACjB,eAAe;IACf,qCAAqC;AACzC","sourcesContent":[".main-menu {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 15px 20px;\n    font-family: 'Monsterrat', sans-serif;\n}\n\n.left-menu__item, .right-menu__item {\n    text-transform: uppercase;\n    font-size: 14px;\n    font-weight: 500;\n    color: #495057;\n    margin: 10px 15px;\n    cursor: pointer;\n    /* border-right: 1px solid #e9ecef; */\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
