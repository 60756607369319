// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background-color: #f1f5f4;
  padding: 100px 235px;
  border-bottom: '1px solid #e9ecef';
}`, "",{"version":3,"sources":["webpack://./src/assets/css/footer.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,oBAAoB;EACpB,kCAAkC;AACpC","sourcesContent":[".footer {\n  background-color: #f1f5f4;\n  padding: 100px 235px;\n  border-bottom: '1px solid #e9ecef';\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
