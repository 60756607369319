// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.viewcard-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    padding: 30px 30px;
    position: relative;
}

.viewcard-item {
    max-width: 320px;
}

.viewcard-item__sale {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #fb0505e8;
    color: #fff;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 0 0 0 10px;
}

@media (max-width: 1200px)
{
    .viewcard-container {
        grid-template-columns: repeat(3, 1fr);
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/css/shop.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qCAAqC;IACrC,cAAc;IACd,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,2BAA2B;IAC3B,WAAW;IACX,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;;IAEI;QACI,qCAAqC;IACzC;AACJ","sourcesContent":[".viewcard-container {\n    display: grid;\n    grid-template-columns: repeat(4, 1fr);\n    grid-gap: 20px;\n    padding: 30px 30px;\n    position: relative;\n}\n\n.viewcard-item {\n    max-width: 320px;\n}\n\n.viewcard-item__sale {\n    position: absolute;\n    top: 0;\n    right: 0;\n    background-color: #fb0505e8;\n    color: #fff;\n    padding: 5px 10px;\n    font-size: 14px;\n    font-weight: 700;\n    text-transform: uppercase;\n    border-radius: 0 0 0 10px;\n}\n\n@media (max-width: 1200px)\n{\n    .viewcard-container {\n        grid-template-columns: repeat(3, 1fr);\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
